<script setup lang="ts">
import { getTotalPrice } from '@/services/price';
import { useBookingDataStore } from '@/stores/bookingData';

const bookingData = useBookingDataStore();
</script>

<template>
  <div class="subtotal">Total: <span class="discounted-price" v-if="bookingData.discount > 0">{{ getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options) }} € </span>{{ getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options, bookingData.discount) }} €<span v-if="bookingData.discount > 0"> (-{{ bookingData.discount * 100 }}%)</span></div>
</template>

<style scoped>
.discounted-price {
  text-decoration: line-through !important;
  color: red;
}
</style>
