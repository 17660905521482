import { AxiosError } from "axios";
import http from "./api_base";
import { formatDate, formatDateGSheet } from "@/utils.ts/date";

async function genericGet<T>(path: string, param?: string) {
  try {
    const {status, data} = await http.get<T>("eauget-ajax.php", {params: { path, param } });
    if (status === 200) {
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

async function getAvailability(year: number, month: number) {
  try {
    const {status, data} = await http.get<Date[]>("eauget-ajax.php", {params: { path: 'availability', year, month } });
    if (status === 200) {
      // initStoreData(data.content);
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

async function getServices(date: Date) {
  return await genericGet<any[]>('services', formatDateGSheet(date));
}

async function getFoods() {
  return await genericGet<any[]>('foods');
}

async function getMenu(date: Date) {
  return await genericGet<any[]>('menu', formatDate(date));
}

async function getDrinks() {
  return await genericGet<any[]>('drinks');
}

async function getOptions() {
  return await genericGet<any[]>('options');
}

async function getTexts() {
  return await genericGet<any[]>('texts');
}

async function startPayment(date: string, type: string, client: string, amount: number, bookingData: any) {
  try {
    console.log('post bookingdata', { date, type, client, amount, data: bookingData });
    const {status, data} = await http.post("eauget-stripe.php", { date, type, client, amount, data: bookingData });
    if (status === 200) {
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

async function getStripePaymentStatus(id: string) {
  try {
    const {status, data} = await http.get("eauget-stripe-status.php", {params: { stripe_id: id } });
    if (status === 200) {
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

async function fetchFoodOrder(token: string) {
  try {
    const {status, data} = await http.get("eauget-ajax.php", {params: { path: 'order', token } });
    if (status === 200) {
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

async function saveMenuChoice(bookingData: any) {
  try {
    const {status, data} = await http.post("eauget-save.php", bookingData);
    if (status === 200) {
      return {
        success: true,
        content: data,
      };
    }
  } catch (error) {
    const _error = error as AxiosError<string>;
    return {
      success: false,
      status: _error.response?.status,
      content: null,
    };
  }
}

export {
  getAvailability,
  getDrinks,
  getFoods,
  getServices,
  getOptions,
  getMenu,
  getTexts,
  getStripePaymentStatus,
  fetchFoodOrder,
  saveMenuChoice,
  startPayment,
};
