<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import subtotal from "./../subtotal.vue";
import { getServices } from "@/api/api";
import { useBookingDataStore } from "@/stores/bookingData";
import { itemsAmount } from "@/api/types";
import { mapItemToBookingItem } from "@/services/price";
import { useTextStore } from "@/stores/texts";

const selectedDiner = ref("dinerlatable");
const selectedDisposition = ref("dinerlasalletables");
const amount = ref<itemsAmount>({});
const services = ref(Array());
const TEXT_infotype = ref("");

const bookingData = useBookingDataStore();
const textStore = useTextStore();

onMounted(async () => {
  const date = bookingData.date;
  if (date == undefined) {
    throw new Error('no date set');
  }
  const s = await getServices(date);
  if (s?.success && s.content) {
    services.value = s.content;
    services.value.forEach((o) => {
      amount.value[o.Id] = 0;
    });

    // Load current data
    if (Array.isArray(bookingData.services)) {
      bookingData.services.forEach((s) => {
        amount.value[s.id] = s.quantity;
        if (s.id == "diner" && s.quantity > 0) {
          selectedDiner.value = s.options[0].id;
          selectedDisposition.value = s.options[1].id;
        }
      });
    }
  }
  TEXT_infotype.value = (await textStore.getText("infotype")) || "";
});

function toggleType(type: string) {
  if (amount.value[type] == 0) {
    amount.value[type] = 2;
  }
}

function selectDiner(type: string) {
  if (
    (amount.value["diner"] <= 6 && type == "dinerlatable") ||
    (amount.value["diner"] >= 6 && type == "dinerlasalle")
  ) {
    selectedDiner.value = type;
    console.log("selectDiner", selectedDiner.value);
  }
  updateDinerBooking();
}

function getService(id: string) {
  return services.value.find((s) => s.Id == id);
}

function selectDisposition(type: string) {
  if (
    (amount.value["diner"] >= 7 && amount.value["diner"] <= 14) ||
    type !== "dinerlasalleovale"
  ) {
    selectedDisposition.value = type;
  }
  updateDinerBooking();
}

function updateDinerBooking() {
  const dinerOption = mapItemToBookingItem(getService(selectedDiner.value), 1);
  const dispositionOption = mapItemToBookingItem(
    getService(selectedDisposition.value),
    1
  );

  const services = mapItemToBookingItem(
    getService("diner"),
    amount.value["diner"],
    [dinerOption, dispositionOption]
  );
  services.packagePrice = dinerOption.packagePrice;

  bookingData.updateService(services);
}

watch(
  () => amount.value["diner"],
  async (curr, prev) => {
    console.log("amount changed", curr);
    if (curr > 6) {
      selectedDiner.value = "dinerlasalle";
    }
    if (curr < 6) {
      selectedDiner.value = "dinerlatable";
    }

    if (curr < 7 || curr > 14) {
      selectedDisposition.value = "dinerlasalletables";
    }

    updateDinerBooking();
  }
);

function canGoNext() {
  return amount.value["diner"] > 0 || amount.value["logement"] > 0;
}

watch(
  () => amount.value["logement"],
  async (curr, prev) => {
    bookingData.updateService(
      mapItemToBookingItem(getService("logement"), amount.value["logement"])
    );
  }
);
</script>

<template>
  <div
    class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type"
  >
    <div class="bpa-front-default-card">
      <!---->
      <div class="bpa-front-dc--body">
        <!---->
        <div class="el-row">
          <div
            class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
          >
            <div
              v-if="services.length > 0"
              class="bpa-front-module-container bpa-front-module--service"
            >
              <div class="bpa-front-module-heading">Votre réservation</div>
              <p>{{ TEXT_infotype }}</p>
              <div class="bpa-front-module--service-items-row">
                <card-item
                  :title="getService('diner').Titre"
                  :description="getService('diner').Description"
                  priceLabel="Découvrez l'expérience"
                  :price="'à partir de ' + getService('diner').Prix"
                  :pictureUrl="getService('diner').Photo"
                  v-model="amount[getService('diner').Id]"
                  :isSelected="amount[getService('diner').Id] > 0"
                  :hasQuantity="true"
                  quantityLabel="Personnes"
                  :minValue="2"
                  :maxValue="getService('diner').Maximum"
                  @click="toggleType(getService('diner').Id)"
                ></card-item>
                <card-item
                  :title="getService('logement').Titre"
                  :description="getService('logement').Description"
                  priceLabel="Découvrez l'expérience"
                  :price="'à partir de ' + getService('logement').Prix"
                  :pictureUrl="getService('logement').Photo"
                  v-model="amount[getService('logement').Id]"
                  :isSelected="amount[getService('logement').Id] > 0"
                  :hasQuantity="true"
                  quantityLabel="Personnes"
                  :minValue="2"
                  :maxValue="getService('logement').Maximum"
                  @click="toggleType(getService('logement').Id)"
                ></card-item>
              </div>
              <!---- Choice of table or full room-->
              <span v-if="amount[getService('diner').Id] > 0">
                <div class="bpa-front-module-heading">Le dîner</div>
                <div class="bpa-front-module--service-items-row">
                  <card-item
                    :title="getService('dinerlatable').Titre"
                    :description="getService('dinerlatable').Description"
                    :price="getService('dinerlatable').Prix"
                    :disabled="amount['diner'] > 6"
                    :pictureUrl="getService('dinerlatable').Photo"
                    v-model="amount[getService('dinerlatable').Id]"
                    :isSelected="selectedDiner == 'dinerlatable'"
                    :hasQuantity="false"
                    @click="selectDiner(getService('dinerlatable').Id)"
                  ></card-item>
                  <card-item
                    :title="getService('dinerlasalle').Titre"
                    :description="getService('dinerlasalle').Description"
                    :price="getService('dinerlasalle').Prix"
                    :disabled="amount['diner'] < 4"
                    :pictureUrl="getService('dinerlasalle').Photo"
                    v-model="amount[getService('dinerlasalle').Id]"
                    :isSelected="selectedDiner == 'dinerlasalle'"
                    :hasQuantity="false"
                    @click="selectDiner(getService('dinerlasalle').Id)"
                  ></card-item>
                </div>
              </span>
              <!---- Choice of room disposition-->
              <span v-if="selectedDiner == 'dinerlasalle'">
                <div class="bpa-front-module-heading">
                  Disposition des tables
                </div>
                <div class="bpa-front-module--service-items-row">
                  <card-item
                    :title="getService('dinerlasalletables').Titre"
                    :description="getService('dinerlasalletables').Description"
                    :disabled="amount['diner'] < 6"
                    :pictureUrl="getService('dinerlasalletables').Photo"
                    v-model="amount[getService('dinerlasalletables').Id]"
                    :isSelected="selectedDisposition == 'dinerlasalletables'"
                    :hasQuantity="false"
                    @click="
                      selectDisposition(getService('dinerlasalletables').Id)
                    "
                  ></card-item>
                  <card-item
                    :title="getService('dinerlasalleovale').Titre"
                    :description="getService('dinerlasalleovale').Description"
                    :disabled="amount['diner'] < 7 || amount['diner'] > 14"
                    :pictureUrl="getService('dinerlasalleovale').Photo"
                    v-model="amount[getService('dinerlasalleovale').Id]"
                    :isSelected="selectedDisposition == 'dinerlasalleovale'"
                    :hasQuantity="false"
                    @click="
                      selectDisposition(getService('dinerlasalleovale').Id)
                    "
                  ></card-item>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot footer-eauget">
              <subtotal></subtotal>
              <!---->
              <button
                type="button"
                @click="$emit('previousStep')"
                style="margin-left: auto"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"
              >
                <!----><!----><span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z"
                    ></path>
                  </svg>
                  Retour
                </span></button
              ><button
                v-if="!bookingData.canGoMenu() && !bookingData.canGoOptions()"
                type="button"
                @click="$emit('nextStep')"
                :disabled="!canGoNext()"
                :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"
              >
                <!----><!----><span>
                  Suivant&nbsp;<strong></strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg
                ></span></button
              ><button
                v-if="bookingData.canGoMenu()"
                type="button"
                @click="$emit('nextStep')"
                :disabled="!canGoNext()"
                :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"
              >
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Nombre de services</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg
                ></span></button
              ><button
                v-if="!bookingData.canGoMenu() && bookingData.canGoOptions()"
                type="button"
                @click="$emit('nextStep')"
                :disabled="!canGoNext()"
                :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"
              >
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Options</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body">
          <!---->
          <!---->
        </div>
        <div class="bpa-sao--footer">
          <button
            type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"
          >
            <!----><!----><span>Annuler</span>
          </button>
          <button
            type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"
          >
            <!----><!----><span>Continuer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
