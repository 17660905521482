<template>
  <Form ref="form" :validation-schema="schema" @submit="submit" @change="onChange" :initial-values="formData">
    <validated-input placeholder="Entrez votre prénom" label="Prénom" name="firstname" type="text" />
    <validated-input placeholder="Entrez votre nom de famille" label="Nom de famille" name="lastname" type="text" />
    <validated-input placeholder="email" label="Entrez votre adresse e-mail" name="email" type="email" />
    <p class="email-alert">⚠️ Attention : Les adresses @hotmail.com et @msn.com bloquent nos emails sans même les placer en spam. Si possible, utilisez une autre adresse (Gmail, iCloud, etc.) pour être sûr de recevoir toutes les informations essentielles à votre réservation. Merci !</p>
    <validated-input placeholder="0470 12 34 56" label="Numéro de téléphone" name="phone" type="phone" />
    <validated-input placeholder="Entrez votre code postal et votre localité" label="Code postal et localité" name="address" type="text" />
    <validated-checkbox label=" Réservation pour une entreprise" name="enterprise" />
    <validated-input v-if="form && form.values.enterprise" placeholder="Entrez le nom de l'entreprise" label="Nom de l'entreprise" name="enterpriseName" type="text" />
    <validated-input v-if="form && form.values.enterprise" placeholder="Entrez votre numéro d'entreprise" label="Numéro d'entreprise" name="enterpriseNumber" type="text" />
    <validated-text-area label="Allergènes" name="allergy" type="textarea" />
    <validated-text-area label="Remarques" name="comment" type="textarea" />
    <div class="button-wrapper">
      <button class="btn btn-success" type="submit">submit</button>
      <button @click="reset" class="btn btn-danger" type="button">Reset</button>
    </div>
  </Form>
</template>
<script setup>
import { Form } from 'vee-validate'
import { ref } from 'vue'
import * as yup from 'yup'
import ValidatedInput from '@/components/input/ValidatedInput.vue'
import ValidatedTextArea from '@/components/input/ValidatedTextArea.vue'
import ValidatedCheckbox from '@/components/input/ValidatedCheckbox.vue'

defineExpose({
  triggerValidate,
  getValues
});

const form = ref(null)
const schema = yup.object().shape({
  firstname: yup.string().required('Le nom est obligatoire').min(2, "fe"),
  lastname: yup.string().required('Le nom est obligatoire').min(2, "fe"),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  address: yup.string().required(),
  enterpriseName: yup.string(),
  enterpriseNumber: yup.string(),
  allergy: yup.string(),
  comment: yup.string(),
  /*job_type: yup.string().required('Job Type field is required'),
  customer_name: yup.string().required('Customer Name field is required')*/
})

const formData = ref({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  address: '',
  enterprise: false,
  enterpriseNumber: '',
  enterpriseName: '',
  allergy: '',
  comment: ''
})

function onChange() {
  console.log('values change', form.value, form.value.values, form.value.values.enterprise, formData.value.enterprise);

}

function triggerValidate() {
  console.log('VALIDATE');
  return form.value.validate();
}

function getValues() {
  return form.value.values;
}

function submit(values) {
  // implement your logic here
  //form.value.resetForm()
  console.log('submit', values)
}
const reset = () => {
  form.value.resetForm()
}
</script>
<style scoped>
.btn {
  height: 35px;
  padding: 0px 20px;
  background-color: gray;
  color: white;
  border-radius: 5px;
}

.button-wrapper {
  display: flex;
  gap: 10px;
}

.btn-success {
  background-color: rgb(64, 181, 64);
}

.btn-danger {
  background-color: rgb(186, 17, 17);
}

.email-alert {
  color: rgb(83, 93, 113);
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -24px !important;
  margin-bottom: 36px !important;
}
</style>
