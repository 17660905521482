<script setup lang="ts">
import { useBookingDataStore } from "@/stores/bookingData";
import { getAvailability } from "../../api/api";
import { onMounted, ref, watch } from "vue";
import { useTextStore } from "@/stores/texts";
import { formatDateGSheet } from "@/utils.ts/date";


const bookingData = useBookingDataStore();
const textStore = useTextStore();

let lastMonth = 0;
let lastYear = 0;

const TEXT_infodate = ref("");

let availableDates = ref(Array<Date>());
let priceDates = ref(Array<String>());
let discountDates = ref(Array<number>());

onMounted(async () => {
  lastMonth = new Date().getMonth();
  lastYear = new Date().getFullYear();
  await fetchNextMonth();
  if (bookingData.date !== undefined) {
    selectedDate.value = bookingData.date;
    // bookingData.updateDate(selectedDate.value);
  } else {
    // selectedDate.value = new Date(availableDates.value[0]);
  }

  TEXT_infodate.value = (await textStore.getText("infodate")) || "";

  watch(
    () => selectedDate.value,
    async (curr, prev) => {
      if (curr !== undefined) {
        const priceDataIndex = availableDates.value.findIndex((d: any) => d == formatDateGSheet(curr));
        let disc = 0;
        if (priceDataIndex > -1) {
          disc =  discountDates.value[priceDataIndex];
        }
        bookingData.updateDate(curr, priceDates.value[priceDataIndex].toString(), disc);
      }
    }
  );

  updateDateColors();

  /*for (let i = 0; i < 11; i++) {
    await fetchNextMonth();
    await new Promise(f => setTimeout(f, 1500));
  }*/
});


function updateDateColors() {
  const dates = document.querySelectorAll("[data-v-date]");
  Array.from(dates).forEach((v: any) => {
    if (v.childElementCount < 1) {
      return;
    }
    const priceDataIndex = availableDates.value.findIndex((d: any) => d == v.dataset.vDate);
    if (priceDataIndex > -1) {
      if (priceDates.value[priceDataIndex] == 'special') {
        v.children[0].style = "background-color: var(--date-color-price-special) !important";
      }
      if (priceDates.value[priceDataIndex] == 'discount') {
        v.children[0].style = "background-color: var(--date-color-price-discount) !important";
      }
      if (priceDates.value[priceDataIndex] == 'base') {
        v.children[0].style = "background-color: var(--date-color-price-base) !important";
      }
      if (priceDates.value[priceDataIndex] == 'high') {
        v.children[0].style = "border: thin solid black !important";
      }
    }
  })
}

function monthChanged() {
  setTimeout(() => { updateDateColors(); }, 0);
}

async function fetchNextMonth() {
  const v = await getAvailability(lastYear, lastMonth + 1);
  if (v?.success && v.content) {
    availableDates.value.push(...v.content.map((d: any) => d.date));
    priceDates.value.push(...v.content.map((d: any) => d.price));
    discountDates.value.push(...v.content.map((d: any) => d.discount));
    lastMonth++;
    if (lastMonth == 12) {
      lastMonth = 0;
      lastYear++;
    }
  }
}

function canGoNext() {
  return selectedDate.value !== undefined;
}

const selectedDate = ref<Date>();
</script>

<template>
  <div
    class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-date"
  >
    <div class="bpa-front-default-card">
      <!---->
      <div class="bpa-front-dc--body">
        <div class="el-row">
          <div
            class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
          >
            <div
              class="bpa-front-module-container bpa-front-module--date-and-time"
            >
              <!---->
              <div class="el-row">
                <div
                  class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
                >
                  <div class="bpa-front-module-heading">Date</div>
                  <!---->
                </div>
              </div>
              <div class="bpa-front--dt__wrapper">
                <div class="bpa-front--dt__col calendar">
                  <div class="bpa-front--dt__calendar">
                    <!---->
                    <v-locale-provider locale="fr">
                      <v-date-picker
                        mode="date"
                        title="Date sélectionnée"
                        header="---"
                        ref="bkp_front_calendar"
                        v-model="selectedDate"
                        :allowed-dates="availableDates"
                        class="bpa-front-v-date-picker"
                        :masks="{ weekdays: 'WWW' }"
                        :first-day-of-week="1"
                        locale="fr"
                        is-required
                        @update:month="monthChanged"
                      >
                    </v-date-picker>
                    </v-locale-provider>
                    <!-- <v-date-picker mode="date" ref="bkp_front_calendar" :min-date="jsCurrentDate" :max-date="booking_cal_maxdate" v-model="selectedDate" :disabled-dates="v_calendar_disable_dates" class="bpa-front-v-date-picker" @update:v-month-page="bpaMoveMonth" :attributes="v_calendar_attributes" :first-day-of-week="first_day_of_week" :locale="site_locale" :masks="{weekdays: 'WWW'}" is-required :model-config="modelConfig"></v-date-picker> -->
                    <!---->
                  </div>
                </div>
                <div
                  class="bpa-front--dt__col bpa-front-dt__timeslot-col bpa-front-dt-col__is-visible"
                >
                  <div class="bpa-front--dt__time-slots">
                    <!---->
                    <div class="calendar_description">
                      <div class="bpa-front-module-heading">
                        Calendrier
                      </div>
                      <ul>
                        <li class="calendar_price_special">Profitez de nos offres spéciales</li>
                        <li class="calendar_price_base">Profitez de nos offres permanentes</li>
                        <li class="calendar_price_discount">Profitez de nos offres remisées</li>
                        <li class="calendar_price_selected">Date sélectionnée</li>
                      </ul>
                      <br />
                      <div class="bpa-front-module-heading">
                        Créneau horaire
                      </div>
                      <p>A partir de 18h</p>
                      <br />
                      <p>{{ TEXT_infodate }}</p>
                    </div>
                    <!---->
                    <!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot">
              <button
                type="button"
                @click="$emit('nextStep')"
                :disabled="!canGoNext()"
                :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"
              >
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Type de réservation</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg>
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  --date-color-price-special: #7ed95780;
  --date-color-price-discount: #9f958380;
  --date-color-price-base: #5271ff80;
  --date-color-selected: #ffbd5980;
  --date-color-first-avail: #c5c5c580;
}


:deep(.v-date-picker-month__day--selected .v-btn) {
  background-color: var(--date-color-selected) !important;
  outline: 3px solid var(--date-color-selected) !important;
  color: black !important;
}

:deep(.v-btn--variant-outlined) {
  border: none !important;
}

:deep(.v-picker-title) {
  padding-bottom: 0px;
}

:deep(.v-picker__body) {
  border: 1px solid #CFD6E5;
  margin-bottom: 2px;
}

:deep(.v-date-picker-controls__month-btn) {
  font-size: 20px;
}

:deep(.v-btn--disabled) {
  cursor: not-allowed;
  pointer-events: all;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
}

@media (max-width: 576px) {
  .bpa-front-module--date-and-time {
    display: block !important;
  }


  div.bpa-front--dt__col.calendar {
    margin: 0px;
    padding: 0px !important;
  }

  :deep(.v-date-picker) {
    margin: auto;
    width: 100%;
  }
}

.calendar_first-avail {
  border: 3px solid var(--date-color-first-avail) !important;
}

.calendar_description ul li:before {
  content: "·";
  color: grey;
  font-size: 150px;
  vertical-align: middle;
  line-height: 20px;
}

.calendar_price_special:before {
  color: var(--date-color-price-special) !important;
}

.calendar_price_discount:before {
  color: var(--date-color-price-discount) !important;
}

.calendar_price_base:before {
  color: var(--date-color-price-base) !important;
}

.calendar_price_first_availability:before {
  content: "\2B58" !important;
  font-size: 23px !important;
  color: #e6e7e8 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.calendar_price_selected:before {
  content: "\2B58" !important;
  font-size: 23px !important;
  color: var(--date-color-selected) !important;
  padding-left: 10px;
  padding-right: 10px;
}

</style>
