export function formatDate(date: Date | undefined) {
  if (date) {
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }
  return "--";
}

export function formatDateGSheet(date: Date | undefined) {
  if (date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
  }
  return "--";
}
