<script setup lang="ts">
import { loadStripe } from '@stripe/stripe-js';
import { startPayment } from '@/api/api';
import { getTotalPrice } from '@/services/price';
import { useBookingDataStore } from '@/stores/bookingData';
import { onMounted } from 'vue';
import { formatDate } from '@/utils.ts/date';

const bookingData = useBookingDataStore();

onMounted(async () => {
  console.log('summary', bookingData.getData());
})

async function pay() {
  console.log('pay');
  // TEST
  // const stripe = await loadStripe('pk_test_51NTrbbHNVmO7iCyMEmgCMOsccolCaV15n7UcT1xRe7oWL1ZZfVfvJcz0X7p8ztvN7bKs7BX44su74aUl4FGhgBAE00y2IiTj6m');

  // PROD
  const stripe = await loadStripe('pk_live_51NTrbbHNVmO7iCyMbgoCQ2LsxwVeGmWSESREEAWCqbfjlG0q0xrhn4vA5MtjDNpNyF35FzO2DhyY84J5ZuM3fuzH00ALDn0vmk');

  if (stripe) {
    console.log('key', stripe);
    console.log('start pay data', bookingData.getData());
    const r = await startPayment(formatDate(bookingData.date!), 'diner', `${bookingData.clientInfo?.firstname} ${bookingData.clientInfo?.lastname}`, parseFloat(getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options)), bookingData.getData());
    if (r?.success && parent) {
      console.log('send iframeLocation message', r.content.checkout_url);
      parent.postMessage({ action: "iframeLocation", url: r.content.checkout_url }, '*')
      // document.location = r.content.checkout_url;
    }


    /*const fetchClientSecret = async () => {
      console.log('start pay data', bookingData.getData());
      const r = await startPayment(formatDate(bookingData.date!), 'diner', `${bookingData.clientInfo?.firstname} ${bookingData.clientInfo?.lastname}`, parseFloat(getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options)), bookingData.getData());
      if (r?.success) {
        return r.content.clientSecret
      }
      return null;
    }

    const checkout = await stripe.initEmbeddedCheckout({
      fetchClientSecret,
    });

    // Mount Checkout
    checkout.mount('#checkout');*/
  }
}
</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body"><!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--service">
                <food-summary />
                <menu-summary v-if="bookingData.menu.length > 0" />
                <div class="bpa-front-module-heading">Allergies</div>
                <div class="allergy-summary">
                <p v-if="bookingData.clientInfo.allergy">{{ bookingData.clientInfo.allergy }}</p>
                <p v-else>Néant</p>
                <br />
                </div>
                <div v-if="bookingData.clientInfo.comment" class="bpa-front-module-heading">Remarques</div>
                <div v-if="bookingData.clientInfo.comment" class="allergy-summary">
                <p>{{ bookingData.clientInfo.comment }}</p>
                <br />
                </div>
                <drink-summary />
                <logement-summary />
                <options-summary />
                <info-summary />
                <br />
                <h2>Montant de la réservation</h2>
                <p>Total à payer:<span class="discounted-price" v-if="bookingData.discount > 0"> {{ getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options) }} €</span> {{ getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks,
                  bookingData.options, bookingData.discount) }} €<span v-if="bookingData.discount > 0"> (-{{ bookingData.discount * 100 }}%)</span></p>
                <div id="checkout">
                  <!-- Checkout will insert the payment form here -->
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot"><!----><button type="button" @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"><!----><!----><span><svg
                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z">
                    </path>
                  </svg>
                  Retour </span></button> <button type="button" @click="pay()"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"><!----><!----><span>
                  Suivant :&nbsp;<strong>Paiement</strong> <svg xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span></button></div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body"><!----> <!----></div>
        <div class="bpa-sao--footer"><button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"><!----><!----><span>Annuler</span></button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>Continuer</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary, p {
    margin: 0px 15px !important;
  }
}

.discounted-price {
  text-decoration: line-through !important;
  color: red;
}</style>
